import React, { useState } from 'react';
import styles from './VerifyPhoneNumberForm.module.css';
import { VerifyPhoneNumber } from '../../util/api';
import InquiryForm from './InquiryForm/InquiryForm';

const VerifyPhoneNumberForm = props => {
  //   const { onClose, isOpen } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('phone');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validatePhoneNumber = number => {
    // Australian mobile numbers start with 04 and have 10 digits total
    const cleanedNumber = number.replace(/\D/g, '');
    return /^04\d{8}$/.test(cleanedNumber);
  };

  const formatPhoneNumber = number => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, '');

    // Format as 04XX XXX XXX
    const match = cleaned.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
    if (match) {
      const [, first, second, third] = match;
      if (third) {
        return `${first} ${second} ${third}`;
      } else if (second) {
        return `${first} ${second}`;
      } else if (first) {
        return first;
      }
    }
    return cleaned;
  };

  const handlePhoneChange = e => {
    const formatted = formatPhoneNumber(e.target.value);
    setPhoneNumber(formatted);
    setError('');
  };

  const handleCodeChange = e => {
    const code = e.target.value.replace(/\D/g, '').slice(0, 6);
    setVerificationCode(code);
    setError('');
  };

  const handleSubmitPhone = async e => {
    e.preventDefault();
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    if (!validatePhoneNumber(cleanNumber)) {
      setError('Please enter a valid Australian mobile number starting with 04');
      return;
    }

    setIsLoading(true);
    try {
      //todo: Send verification code to the phone number
      //   await simulateApiCall();

      const result = await VerifyPhoneNumber({ phoneNumber: cleanNumber });

      if (result.success) {
        setStep('code');
        setError('');
      } else if (result.error) {
        setError(result.error);
      } else {
        setError('Failed to send verification code. Please try again');
      }
    } catch (err) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    if (typeof window !== 'undefined') window.location.reload();
  };

  const handleSubmitCode = async e => {
    e.preventDefault();

    const cleanNumber = phoneNumber.replace(/\D/g, '');
    if (verificationCode.length !== 6) {
      setError('Please enter a valid 6-digit code');
      return;
    }

    setIsLoading(true);
    try {
      //   await simulateApiCall();
      const result = await VerifyPhoneNumber({
        phoneNumber: cleanNumber,
        authCode: verificationCode,
      });

      if (result && result.success) {
        setSuccess(true);
        setError('');
      } else if (result && result.error) {
        setError(result.error);
        setSuccess(false);
      } else {
        // console.log('Invalid verification code. Please try again');
        setError('Invalid verification code. Please try again');
        setSuccess(false);
      }

      //   onClose();
    } catch (err) {
      //   console.error(err);
      setError('Invalid verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <>
        <div className={styles.formContainer}>
          <div className={styles.successMessage}>Phone number verified successfully!</div>
          <hr />

          <InquiryForm {...props} />
        </div>
      </>
    );
  }

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>
        {step === 'phone' ? 'Verify Your Mobile Number' : 'Enter Verification Code'}
      </h2>

      <div className={styles.infoContainer}>
        <p>
          To help us reduce spam, we now require users to verify their phone number before you can
          send any messages.{' '}
          <a href="/p/privacy-policy" target="_BLANK">
            View our privacy policy.
          </a>
        </p>
      </div>

      <form onSubmit={step === 'phone' ? handleSubmitPhone : handleSubmitCode}>
        <div className={styles.inputGroup}>
          {step === 'phone' ? (
            <>
              <label className={styles.label}>Mobile Number</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneChange}
                className={styles.input}
                placeholder="04XX XXX XXX"
                required
                disabled={isLoading}
              />
              <div className={styles.hint}>Enter Australian mobile number (starting with 04)</div>
            </>
          ) : (
            <>
              <label className={styles.label}>Verification Code</label>
              <input
                type="text"
                value={verificationCode}
                onChange={handleCodeChange}
                className={styles.input}
                placeholder="Enter 6-digit code"
                required
                disabled={isLoading}
              />
            </>
          )}
          {error && <div className={styles.error}>{error}</div>}
        </div>

        <button
          type="submit"
          className={styles.button}
          disabled={
            isLoading ||
            (step === 'phone' && !phoneNumber) ||
            (step === 'code' && verificationCode.length !== 6)
          }
        >
          {isLoading ? 'Loading...' : step === 'phone' ? 'Send Code' : 'Verify Code'}
        </button>
      </form>
    </div>
  );
};

export default VerifyPhoneNumberForm;
